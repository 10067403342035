import route, { RouteTypes, mainTabs } from '@/common/route';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useFirstTabRedirect() {
  const router = useRouter();

  const irDetailRedirect = () => router.replace(route.ir.tabs.archives);
  useEffect(() => {
    const mainTabValues = Object.values(mainTabs);
    const currentPath = router.asPath;
    const irDetail = route.ir.detail.archives;
    if (irDetail === currentPath) {
      irDetailRedirect();
      return;
    }
    const matchTab = mainTabValues.find((mainTab) => mainTab === currentPath);

    if (matchTab) {
      const removeSluchTab = matchTab?.slice(1) as Exclude<
        keyof RouteTypes,
        'main' | 'careers' | 'insideNhn'
      >;
      const firstTab = Object.values(route[removeSluchTab].tabs)[0];
      router.replace(firstTab);
    }
    // 추가적인 조건에 따라 필요한 리다이렉트를 계속해서 추가할 수 있습니다.
  }, []);
}
