import type { RouteValue } from '@/common/route';

const outPaths = ['/careers', '/insideNhn'];
const subPathCheck = (path: string, subPath: RouteValue[]) => !subPath.includes(path);
const outPathCheck = (path: string) => outPaths.includes(path);
const getSubPath = (path: string) => {
  if (!path?.includes('/')) return '';
  return path.split('?')[0];
};

const getPath = (path: string, subPath: RouteValue[]) => {
  return subPathCheck(path, subPath) ? getSubPath(path) : path;
};

const isActivePath = (targetPath: RouteValue, pathname: string) => {
  if (!targetPath) return false;

  return pathname === targetPath || pathname.startsWith(`${targetPath}`);
};

const path = {
  subPathCheck,
  outPathCheck,
  getSubPath,
  getPath,
  isActivePath
};
export default path;
