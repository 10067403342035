/* eslint-disable import/extensions */
/* eslint-disable react/no-unstable-nested-components */
import common from '@/common/constants/common';
import { IsMobileProvider } from '@/common/contexts/IsMobileContext';
import { useFirstTabRedirect } from '@/common/hooks/useFirstTabRedirect';
import { useInitQueryClient } from '@/common/hooks/useInitQueryClient';
import { useScrollToTopOnQueryChange } from '@/common/hooks/useScrollToTopOnQueryChange';
import type { ExtendedAppProps, ExtendedNextPage } from '@/common/types/next';
import { helper } from '@/common/utils';
import '@/styles/globals.scss';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appWithTranslation } from 'next-i18next';
import App, { AppContext } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useMemo } from 'react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const DefaultLayout = dynamic(() => import('@/components/layout/DefaultLayout'), {
  ssr: true
});

const PageWithGnbHoc = dynamic(() => import('@/components/unit/PageWithGnbHoc'), {
  ssr: true
});

function MyApp({
  Component,
  pageProps,
  router
}: ExtendedAppProps<
  {
    userAgent: string;
    isMobile: boolean;
  } & Omit<ExtendedNextPage, 'getLayout'>
>) {
  helper.gsapInitRegister();
  const { isMobile } = pageProps;
  // react Query 초기 셋팅

  const queryClient = useInitQueryClient();
  const getLayout = Component.getLayout || helper.noop;

  // gnb 클릭 시 조건에 따라 scrollTo
  useScrollToTopOnQueryChange({ isMobile });

  const GeneralComponent = useMemo(
    () => getLayout(<Component {...pageProps} />, { pageProps, router }),
    [Component, getLayout, pageProps, router]
  );

  const RenderedComponent = useMemo(() => {
    if (Component.displayName === 'services') {
      Object.defineProperty(Component, 'hidePageWithGnbLayout', {
        value: isMobile,
        configurable: true
      });
    }

    if (!Component.hidePageWithGnbLayout) {
      return <PageWithGnbHoc>{GeneralComponent}</PageWithGnbHoc>;
    }

    return GeneralComponent;
  }, [Component.hidePageWithGnbLayout, GeneralComponent]);

  useEffect(() => {
    const links = [
      {
        href: 'https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css'
      },
      {
        href: 'https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable-jp.css'
      },
      {
        href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap'
      }
    ];

    links.forEach(({ href }) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      link.onload = () => console.log(`Stylesheet loaded: ${href}`);
      link.onerror = () => console.error(`Failed to load stylesheet: ${href}`);
      document.head.appendChild(link);
    });
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);
  useFirstTabRedirect();

  if (Component.displayName && ['meetingResult'].includes(Component.displayName)) {
    return GeneralComponent;
  }

  if (Component.displayName && ['ci'].includes(Component.displayName)) {
    return <IsMobileProvider initialValue={isMobile}>{GeneralComponent} </IsMobileProvider>;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <IsMobileProvider initialValue={isMobile}>
        <Head>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="ie=edge" httpEquiv="X-UA-Compatible" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        {/* <Layout> */}
        <DefaultLayout
          hideLayoutFooter={Component.hideLayoutFooter}
          hideLayout={Component.hideLayout}
        >
          {RenderedComponent}
        </DefaultLayout>
        {/* </Layout> */}
        {common.isDev && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
      </IsMobileProvider>
    </QueryClientProvider>
  );
}

const isMobile = (agent: string) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent);
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const userAgent = appContext.ctx.req
    ? appContext.ctx.req?.headers['user-agent']
    : navigator.userAgent;

  const mobile = isMobile(userAgent || '');
  appProps.pageProps.isMobile = mobile;

  return { ...appProps };
};

export default appWithTranslation(MyApp);
