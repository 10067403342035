export const errorMessage: { [key: number]: string } = Object.freeze({
  200: '성공',
  301: '리소스의 위치가 다른 곳인 경우',
  302: '리소스의 위치가 다른 곳인 경우',
  303: '리소스의 위치가 다른 곳인 경우',
  307: '리소스의 위치가 다른 곳인 경우',
  400: '사용자 입력 오류',
  401: '인증되지 않은 요청인 경우 (예, Authorization 헤더가 없는 경우, 토큰이 폐기된 경우, 잘못된 토큰을 보낸 경우)',
  403: '권한이 없는 경우(예, 프로젝트 어드민만 할 수 있는 작업을 일반 멤버가 하는 경우)',
  404: '존재하지 않는 리소스를 요청하는 경우. (예외적으로 권한이 없는 리소스의 경우에도 404가 나오는 경우가 있음)',
  409: '중복되는 리소스 생성 요청의 경우',
  415: 'Content-Type 이 맞지 않는 경우',
  429: '너무 많은 요청을 보내는 경우',
  500: '서버에서 작업에 실패한 경우'
});

// 200: 성공
// 301: 리소스의 위치가 다른 곳인 경우
// 302: 리소스의 위치가 다른 곳인 경우
// 303: 리소스의 위치가 다른 곳인 경우
// 307: 리소스의 위치가 다른 곳인 경우
// 400: 사용자 입력 오류
// 401: 인증되지 않은 요청인 경우 (예, Authorization 헤더가 없는 경우, 토큰이 폐기된 경우, 잘못된 토큰을 보낸 경우)
// 403: 권한이 없는 경우(예, 프로젝트 어드민만 할 수 있는 작업을 일반 멤버가 하는 경우)
// 404: 존재하지 않는 리소스를 요청하는 경우. (예외적으로 권한이 없는 리소스의 경우에도 404가 나오는 경우가 있음)
// 409: 중복되는 리소스 생성 요청의 경우
// 415: Content-Type 이 맞지 않는 경우
// 429: 너무 많은 요청을 보내는 경우
// 500: 서버에서 작업에 실패한 경우
// 그 외 상세한 정보가 필요한 경우 응답 body 내의 header.resultCode, - - - header.resultMessage 를 사용합니다.
// header.resultMessage 는 사람을 위해 제공되는 필드입니다.
// header.resultMessage 는 이해하기 쉬운 형태로 예고 없이 변경될 수 있습니다.
// header.resultMessage 는 적절한 응답인지 확인을 위해 프로그램 로직에서 사용하는 것을 지양해야합니다.
