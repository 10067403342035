/* eslint-disable no-return-assign */
import { baseURL } from '@/common/constants/api';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { MutableRefObject, RefObject } from 'react';
import common from '../constants/common';
import { Tlocales } from '../constants/locale';

const padIndex = (index: number): string => (index < 10 ? `0${index}` : `${index}`);

const isBrowser = (): boolean => typeof window !== 'undefined';

const noop = <T>(value: T): T => value;

const getKeyByValueCheckBooelan = <T extends Record<string, string>>(object: T, value: string) =>
  Object.keys(object).some((k) => k === value && object[k] !== null && object[k] !== undefined);

const gsapContextWrap = (callback: () => void, element: RefObject<HTMLElement>) => {
  if (!element) return;
  gsap.context(callback, [element]);
};
const isNotEmptyObject = (value: any) =>
  typeof value === 'object' && value !== null && Object.keys(value).length > 0;

const isEmptyObject = (obj: any): boolean =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const getDeviceType = (): string => {
  if (typeof window === 'undefined') return 'server';

  const { userAgent } = window.navigator;
  return /Mobi|Android/i.test(userAgent) ? 'mobile' : 'desktop';
};

export const restrictToNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
  event.preventDefault();
  const key = event.which || event.keyCode;
  if (key < 48 || key > 57) event.preventDefault();
};

export const isTrue = (checkArg: any): boolean => Boolean(checkArg);

export const onlyNumber = (value: string) => value.replace(/\D/, '');

export const download = (path: string) => {
  const link = document.createElement('a') as HTMLAnchorElement;
  const filePath = path;
  link.href = filePath;
  link.download = filePath.substring(filePath.lastIndexOf('/') + 1);
  const scrollPosition = window.scrollY; // 현재 스크롤 위치 기억
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.scrollTo(0, scrollPosition); // 스크롤 위치를 원래 위치로 재설정
};
const transformString = (input: string) =>
  input
    .replaceAll(' ', '')
    .replace(/NHN/g, '')
    .replace(/\s+/g, '')
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>]/gi, '')
    .toLocaleLowerCase() || 'nhn';

const logoTransformString = (input: string) =>
  input.includes('Doppelsoft')
    ? input.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>]/gi, '')
    : input.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>]/gi, '').toLocaleUpperCase();

const setObjectProperty = <T, K extends keyof T>(object: T, key: K, newValue: T[K]) => {
  return { ...object, [key]: newValue };
};

const objectUpdate = <T, K extends keyof T>(object: T, key: K, modify: (value: T[K]) => T[K]): T =>
  setObjectProperty(object, key, modify(object[key]));

const sortAndExpandArray = (arr: string[], length: number) => {
  const sortedArr = new Array(length).fill('');
  arr.forEach((item) => {
    const index = parseInt(item.slice(-2), 10) - 1; // 0부터 인덱싱하기 위해 -1을 합니다.
    if (index < length) sortedArr[index] = item;
  });
  return sortedArr;
};

const scrollToViewportPercentage = (element: HTMLElement | null, percentage: number) => {
  if (!element) return;
  const elementRect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  // 스크롤 위치 계산
  const scrollPosition = elementRect.top + window.scrollY - viewportHeight * (1 - percentage);
  // 스크롤 실행
  window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
};

const dateFormatYearMonth = (date: string) => date.slice(0, 10).replaceAll('-', '.');

const getVw = (width: number, vw: number) => (width / 375) * 100 * vw * 0.01;

const removeWhiteSpace = (date: string) => date?.replaceAll('\n', '');

// toLocaleString을 사용하여 숫자를 문자열로 변환하고,
// 천 단위로 쉼표를 추가합니다.
const formatPrice = (price: string | number) => Number(price).toLocaleString('ko-KR');

const dateTransform = (date: string) => date.replace(/(\d{4})(\d{2})(\d{2})/, '$1년 $2월 $3일');
const dateTransform2 = (date: string) => date.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');

const getMatchStyles = (match: boolean, matchStyls: any, notMatchStyls?: any) =>
  match ? matchStyls : notMatchStyls || '';
const getMatchLogic = (match: boolean, logic1: any, logic2?: any) =>
  match ? logic1 : logic2 || '';

const getInputValueDiff = (
  value: number,
  length: number,
  apeend: (value: number) => void,
  remove: (value: number) => void
) => {
  const diff = value - length;
  if (diff > 0) return apeend(diff);
  if (diff < 0) return remove(Math.abs(diff));
};

const getRandomItems = <T extends {}>(data: T[], count: number): T[] => {
  const randomIndices: number[] = [];
  while (randomIndices.length < count) {
    const randomIndex = Math.floor(Math.random() * data.length);
    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
    }
  }
  const randomItems = randomIndices.map((index) => data[index]);
  return randomItems;
};

const isDevelop = () => baseURL.includes('dev-home');

const isOutLink = (link: string) => link.includes('https://') || link.includes('http://');

const imageUrl = (() => {
  if (common.isDev) {
    return 'http://localhost:3000';
  }
  if (isDevelop()) {
    return 'https://staticdev.nhnent.com/static/site/corp';
  }
  return 'https://static.nhnent.com/static/site/corp';
})();

const getUrl = () => {
  if (common.isDev) {
    return 'http://localhost:3000';
  }
  if (isDevelop()) {
    return 'https://dev-newhome.nhn.com/';
  }
  return 'https://nhn.com';
};

const appendQueryParams = (url: string, params: Record<string, string>): string => {
  const urlObject = new URL(url);
  Object.keys(params).forEach((key) => urlObject.searchParams.append(key, params[key]));
  return urlObject.toString();
};

const imagePrefixWithQueryParameter = (
  str: string,
  params: Record<string, string> = {}
): string => {
  const url = `${imageUrl}/${str}`;
  return appendQueryParams(url, params);
};

const imagePrefix = (str: string) => `${imageUrl}${str}`;
const imageLocalPrefix = (str: string) => `${'http://localhost:3000'}${str}`;
const getApiImageUrl = (value: string) =>
  isDevelop() ? `https://dev-home.nhn.com${value}` : `https://www.nhn.com${value}`;

const extractTabValue = (url: string) => {
  const match = url.match(/[?&]tab=([^&]+)/);
  return match ? match[1] : ''; // "tab=" 이후의 값 반환
};
const calcValues = (values: any[], card: MutableRefObject<any>, cardOffsetYRatio: number) => {
  const scrollHeight = card.current?.clientHeight;
  const ratio = cardOffsetYRatio;
  if (!scrollHeight) return;
  const currentYOffset = Math.round(scrollHeight * ratio);

  let rv;
  //   현재 씬(스크롤 섹션)에서 스크롤 된 범위를 비율로 구하기
  const scrollRatio = currentYOffset / scrollHeight;

  const [startAniValue, endAniValue, animation] = values;

  if (values.length === 3 || values.length === 4) {
    // start ~ end 사이에 애니메이션 실행
    const partScrollStart = Number(animation.start) * scrollHeight;
    const partScrollEnd = Number(animation.end) * scrollHeight;
    const partScrollHeight = partScrollEnd - partScrollStart;
    if (currentYOffset >= partScrollStart && currentYOffset <= partScrollEnd) {
      rv =
        ((currentYOffset - partScrollStart) / partScrollHeight) * (endAniValue - startAniValue) +
        startAniValue;
    } else if (currentYOffset < partScrollStart) {
      rv = startAniValue;
    } else if (currentYOffset > partScrollEnd) {
      rv = endAniValue;
    }
  } else {
    rv = scrollRatio * (values[1] - values[0]) + values[0];
  }

  return rv;
};
const gsapInitRegister = () => {
  if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  }
};
const getCurrentLanguageFont = (currentLanguage: Tlocales) => {
  const font: { [key in Tlocales]: string } = {
    'ko-KR': 'font-pretendard',
    'en-US': 'font-pretendard',
    'ja-JP': 'font-pretendardJP'
  };

  return font[currentLanguage];
};
const helper = {
  padIndex,
  getKeyByValueCheckBooelan,
  transformString,
  sortAndExpandArray,
  isBrowser,
  noop,
  objectUpdate,
  getDeviceType,
  dateFormatYearMonth,
  getVw,
  getRandomItems,
  getUrl,
  getCurrentLanguageFont,
  removeWhiteSpace,
  formatPrice,
  dateTransform,
  dateTransform2,
  isEmptyObject,
  getInputValueDiff,
  restrictToNumbers,
  isOutLink,
  download,
  logoTransformString,
  imagePrefixWithQueryParameter,
  imagePrefix,
  imageLocalPrefix,
  isNotEmptyObject,
  getApiImageUrl,
  getMatchStyles,
  getMatchLogic,
  extractTabValue,
  scrollToViewportPercentage,
  onlyNumber,
  gsapContextWrap,
  isTrue,
  calcValues,
  isDevelop,
  gsapInitRegister
};

export default helper;
