import { useRouter } from 'next/router';
import {
  useAniDisabled,
  useCurretEventPop,
  useGnbSticky,
  useRouterType
} from '@/common/store/state';
import gsap from 'gsap';
import { helper } from '@/common/utils';
import { useEffect, useRef } from 'react';
import { i18n } from 'next-i18next';

// header,pageTitle,gnb를 더한 값
const ACTIVE_HEIGHT = 364;
const ACTIVE_HEIGHT_MOBILE = 142; // 56 + 156
const INIT_Y = 0;

const getActiveHeight = (isMob: boolean) => {
  return isMob ? helper.getVw(ACTIVE_HEIGHT_MOBILE, window.innerWidth) : ACTIVE_HEIGHT;
};
function useScrollToTopOnQueryChange({ isMobile }: { isMobile: boolean }): void {
  const localeChangeEvent = useRef(i18n?.language);
  const router = useRouter();
  const { isSticky } = useGnbSticky();
  const { routerType } = useRouterType();
  const { setAniDisabled } = useAniDisabled();
  const { setIsCurrentEventPop } = useCurretEventPop();
  const currentEventPop = useRef(false);
  const routerChangeScrollAni = (y: number, activeAniDisabled: boolean) => {
    if (isMobile) return window.scrollTo({ top: y });
    if (activeAniDisabled) setAniDisabled(true);
    gsap.to(window, {
      scrollTo: { y, autoKill: false },
      onComplete: () => {
        if (activeAniDisabled) setAniDisabled(false);
      }
    });
  };

  useEffect(() => {
    let shouldScrollRestore: null | { x: number; y: number };
    window.history.scrollRestoration = 'auto';
    const cachedScrollPositions = JSON.parse(
      window.sessionStorage.getItem('cachedScrollPositions') || '[]'
    );

    const saveScrollPosition = () => {
      const { scrollX, scrollY } = window;
      cachedScrollPositions.push([scrollX, scrollY]);
      window.sessionStorage.setItem('cachedScrollPositions', JSON.stringify(cachedScrollPositions));
    };

    const restoreScrollPosition = () => {
      if (shouldScrollRestore) {
        const { x, y } = shouldScrollRestore;
        setTimeout(() => window.scrollTo(x, y), 0);
        shouldScrollRestore = null;
      }
      window.history.scrollRestoration = 'auto';
    };

    router.events.on('routeChangeStart', () => {
      saveScrollPosition();
    });
    router.events.on('routeChangeComplete', restoreScrollPosition);
    router.beforePopState(() => {
      currentEventPop.current = true;
      setIsCurrentEventPop(currentEventPop.current);
      setTimeout(() => {
        setIsCurrentEventPop(false);
      }, 500);
      if (cachedScrollPositions.length > 0) {
        const scrollPosition = cachedScrollPositions.pop();
        if (scrollPosition) {
          shouldScrollRestore = {
            x: scrollPosition[0],
            y: scrollPosition[1]
          };
        }
      }
      window.history.scrollRestoration = 'manual';
      return true;
    });
  }, []);
  useEffect(() => {
    const handleRouteChange = () => {
      if (localeChangeEvent.current !== i18n?.language)
        return (localeChangeEvent.current = i18n?.language);
      if (currentEventPop.current) return (currentEventPop.current = false);
      if (!['gnb', 'sideTab'].includes(routerType)) return;
      const { scrollHeight, clientHeight } = document.documentElement;
      // 해당 페이지의 스크롤 값이 clientHeight + ACTIVE_HEIGHT 보다 여유가 있을 경우 &&  gnb sticky 상태 일 경우
      const activeHeight = getActiveHeight(isMobile);
      if (scrollHeight - (clientHeight + activeHeight - 1) > 0 && isSticky) {
        routerChangeScrollAni(activeHeight, true);
      } else {
        routerChangeScrollAni(INIT_Y, false);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, routerType]);
}

export default useScrollToTopOnQueryChange;
