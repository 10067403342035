const company = '/company';
const services = '/services';
const pr = '/pr';
const ir = '/ir';
const esg = '/esg';
const tab = 'tab';
const subTab = 'subTab';
const careers = 'https://careers.nhn.com/';
const insideNhn = 'https://inside.nhn.com/br/index.nhn';
const page = 'page';
const generalTab = 'archives';

export const mainTabs = {
  company,
  services,
  pr,
  ir,
  generalTab,
  esg
};
export const tabRoute = {
  company,
  services,
  pr,
  ir,
  generalTab,
  esg
};
const route: RouteTypes = {
  main: '/',
  company: {
    path: company,
    tabs: {
      about: `${company}?${tab}=about`,
      domesticCorporation: `${company}?${tab}=domesticCorporation&${subTab}=paymentAd`,
      overseasCorporation: `${company}?${tab}=overseasCorporation&${subTab}=japan`,
      brand: `${company}?${tab}=brand`
    }
  },
  services: {
    path: services,
    tabs: {
      game: `${services}?${tab}=game`,
      paymentAd: `${services}?${tab}=paymentAd`,
      commerce: `${services}?${tab}=commerce`,
      technology: `${services}?${tab}=technology`,
      contents: `${services}?${tab}=contents`
    }
  },
  pr: {
    path: pr,
    detail: `${pr}/`,
    tabs: {
      pressRelease: `${pr}?${tab}=pressRelease`
    }
  },
  ir: {
    path: ir,
    detail: {
      archives: `${ir}/${generalTab}`
    },
    tabs: {
      disclosure: `${ir}?${tab}=disclosure&${subTab}=disclosure`,
      stock: `${ir}?${tab}=stock`,
      financials: `${ir}?${tab}=financials&${subTab}=consolidatedFinancial`,
      events: `${ir}?${tab}=events&${subTab}=announcementPerformance`,
      archives: `${ir}?${tab}=${generalTab}&${subTab}=generalNotice&${page}=1`,
      ENArchives: `${ir}?${tab}=${generalTab}&${subTab}=generalMeetingResult`,
      mobileEvents: `${ir}?${tab}=mobileEvents`,
      mobileAnnouncementPerformance: `${ir}?${tab}=mobileAnnouncement`,
      mobileMeetingReservation: `${ir}?${tab}=mobileMeetingReservation`
    }
  },
  esg: {
    path: esg,
    tabs: {
      esgManagement: `${esg}?${tab}=esgManagement&${subTab}=strategies`,
      environment: `${esg}?${tab}=environment&${subTab}=managementSystem`,
      society: `${esg}?${tab}=social&${subTab}=humanRightManagement`,
      governance: `${esg}?${tab}=governance&${subTab}=shareholders`,
      businessConduct: `${esg}?${tab}=businessConduct&${subTab}=ethicsPolicy`,
      reportPolicy: `${esg}?${tab}=reportPolicy&${subTab}=report`
    }
  },
  careers: {
    path: careers
  },
  insideNhn: {
    path: insideNhn
  }
};

export interface RouteTypes {
  main: string;
  company: {
    path: string;
    tabs: {
      about: string;
      domesticCorporation: string;
      overseasCorporation: string;
      brand: string;
    };
  };
  services: {
    path: string;
    tabs: {
      game: string;
      paymentAd: string;
      commerce: string;
      technology: string;
      contents: string;
    };
  };
  pr: {
    path: string;
    detail: string;
    tabs: {
      pressRelease: string;
    };
  };
  ir: {
    path: string;
    detail: {
      archives: string;
    };
    tabs: {
      disclosure: string;
      stock: string;
      financials: string;
      events: string;
      mobileEvents: string;
      mobileAnnouncementPerformance: string;
      mobileMeetingReservation: string;
      archives: string;

      ENArchives: string;
    };
  };
  esg: {
    path: string;
    tabs: {
      esgManagement: string;
      environment: string;
      society: string;
      governance: string;
      businessConduct: string;
      reportPolicy: string;
    };
  };
  careers: {
    path: string;
    tabs?: {};
  };
  insideNhn: {
    path: string;
    tabs?: {};
  };
}

export type RouteKeys = keyof typeof route;
export type RouteValue = (typeof route)[RouteKeys];

export default route;
