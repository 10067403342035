/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { errorMessage } from '@/common/constants/api/errorMessage';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios, { isAxiosError } from 'axios';
import { i18n } from 'next-i18next';

const defaultAxios = axios.create({
  baseURL: 'api/',
  timeout: 20000,
  headers: {
    common: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_AUTH_KEY}`,
      'Content-Type': 'application/json'
    }
  }
});
// axios.defaults.headers.common.Referer = `${process.env.NEXT_PUBLIC_BASEURL}`;

defaultAxios.interceptors.request.use((config) => {
  const isMobile =
    config.url !== '/footer/policy-privacy-versions' &&
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) &&
    navigator.userAgent.toLowerCase().indexOf('mobile') > -1;

  const language = i18n?.language || 'ko-KR';

  if (config.headers) {
    const newConfig = { ...config };
    newConfig.headers.Caller = isMobile ? 'MOBILE' : 'WEB';
    if (config.url === '/footer/policy-privacy-versions') {
      newConfig.headers.Caller = 'WEB';
    }
    if (config.url === '/pr/press-releases') {
      newConfig.headers.language = 'ko-KR';
    } else {
      newConfig.headers.language = language;
    }
    return newConfig;
  }
  return config;
});
const getHttpHeaders = (isAuthenticated = false): AxiosRequestConfig => {
  if (isAuthenticated) {
    return {
      headers: {
        Authorization: 'Bearer YOUR_TOKEN'
      }
    };
  }

  return {};
};

const get = <T = any>(path: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<T>> =>
  defaultAxios.get(path, config);

const del = <T = any>(path: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<T>> =>
  defaultAxios.delete(path, config);

const post = <T = any>(
  path: string,
  data: T,
  config?: AxiosRequestConfig<any> | undefined
): Promise<AxiosResponse> => defaultAxios.post(path, data, config);

const put = (path: string, data: any): Promise<AxiosResponse> => defaultAxios.post(path, data);

const patch = (path: string, data: any): Promise<AxiosResponse> =>
  defaultAxios.post(path, data, getHttpHeaders());

const handleError = (err: unknown) => {
  if (process.env.NODE_ENV !== 'production') {
    if (isAxiosError(err) && err.response !== undefined) {
      const { status, data } = err.response;
      if (data && data.header) {
        console.error(errorMessage[data.header.resultCode] || data.header.resultMessage);
        // alert(errorMessage[data.header.resultCode] || data.header.resultMessage);
        return errorMessage[data.header.resultCode] || data.header.resultMessage;
      }
      console.error(errorMessage[status]);
      return errorMessage[status];
    }
  }
  return Promise.resolve(err);
};

export default {
  get,
  del,
  post,
  put,
  patch,
  handleError
};
