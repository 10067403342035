import { ReactNode, createContext, useContext } from 'react';

interface IIsMobileProvider {
  children: ReactNode;
  initialValue: boolean;
}
const IsMobileContext = createContext(false);

export function useIsMobile() {
  return useContext(IsMobileContext);
}

export function IsMobileProvider({ children, initialValue }: IIsMobileProvider) {
  return <IsMobileContext.Provider value={initialValue}>{children}</IsMobileContext.Provider>;
}
