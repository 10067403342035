import type {
  MeetingTime,
  MeetingType
} from '@/components/specific/Ir/Events/MeetingReservation/formType';
import { create } from 'zustand';

export type DeviceType = 'desktop' | 'mobile';
type RouterType = 'gnb' | 'main' | 'sub' | 'sideTab' | 'pr' | '';
interface DeviceState {
  deviceType: DeviceType;
  setDeviceType: (type: DeviceType) => void;
}

interface ScrollType {
  isScrollPrevented: boolean;
  scrollLock: () => void;
  scrollunLock: () => void;
}

interface IsRunningAniType {
  isGnbHeaderWithRunning: boolean;
  setIsGnbHeaderWithRunning: (value: boolean) => void;
  setIsRunningAni: (value: boolean) => void;
  isRunningAni: boolean;
}

interface UsePageTitleVisibleType {
  isPageTitleVisible: boolean;
  setIsPageTitleVisible: (value: boolean) => void;
}

interface UsePageTitleVisibleType {
  isPageTitleVisible: boolean;
  setIsPageTitleVisible: (value: boolean) => void;
}

interface UseAnimationInProgress {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
}

interface UseRouterType {
  routerType: RouterType;
  setRouterType: (value: RouterType) => void;
}

interface UseMeetingType extends MeetingType, MeetingTime {
  setReserveTimeType: (value: string) => void;
  setReserveDate: (value: string) => void;
  setReserveTime: (value: string) => void;
  setMeetingType: (value: string) => void;
  setMeetingDetailType: (value: string) => void;
}

interface UseMeetingCheckbox {
  disabeld: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
  setDisabled: (value: boolean) => void;
}

interface UseAboutMobileAni {
  isComplete: boolean;
  setIsComplete: (value: boolean) => void;
}

export interface UseOutClick {
  isActive: boolean;
  setIsActive: (value: boolean) => void;
}

export interface UseCurretEventPop {
  currentEventPop: boolean;
  setIsCurrentEventPop: (value: boolean) => void;
}

export interface UseMainBannerIndex {
  activeBannerIndex: number;
  setActiveBannerIndex: (index: number) => void;
}

interface UseMeetingEmailValidation {
  isVisitroValidation: boolean;
  isAgencyValidation: boolean;
  setIsVisitroValidation: (value: boolean) => void;
  setIsAgencyValidation: (value: boolean) => void;
}
interface UseGnbSticky {
  isSticky: boolean;
  setIsSticky: (value: boolean) => void;
}
interface UseGnbSticky {
  isSticky: boolean;
  setIsSticky: (value: boolean) => void;
}
interface UseGnbStickyFix {
  isStickyFix: boolean;
  setIsStickyFix: (value: boolean) => void;
}

interface UseVisibleHeader {
  isVisibleHeader: boolean;
  setIsVisibleHeader: (value: boolean) => void;
}

interface UseAniDisabled {
  aniDisabled: boolean;
  setAniDisabled: (value: boolean) => void;
}
interface UseIsScrollAni {
  isScrollAni: boolean;
  setIsScrollAni: (value: boolean) => void;
}

interface UseBannerSliderVideo {
  currentSliderIndex: number;
  currentVideoEl: HTMLVideoElement | null;
  setCurrentSliderIndex: (index: number) => void;
  setCurrentVideoEl: (video: HTMLVideoElement) => void;
}
interface UseMainWidthChange {
  mainWidthChange: 'full' | 'notFull';
  setMainWidthChange: (type: 'full' | 'notFull') => void;
}

interface UseServiceQueryTab {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}

export const useDeviceState = create<DeviceState>((set) => ({
  deviceType: 'desktop',
  setDeviceType: (type) => set({ deviceType: type })
}));

export const usePreventScrollStore = create<ScrollType>((set) => ({
  isScrollPrevented: false,
  scrollLock: () => set({ isScrollPrevented: true }),
  scrollunLock: () => set({ isScrollPrevented: false })
}));

export const useMeetingInfo = create<UseMeetingType>((set) => ({
  reserveDate: '', // 예약날짜 YYYY.MM.DD
  reserveTime: '', // 예약 시간 hh:mm
  reserveTimeType: '',
  meetingDetailType: '1',
  meetingType: '1',
  handleMeeingInfoChange: (key: string, value: string | number) => set({ [key]: value }),
  setReserveDate: (value: string) => set({ reserveDate: value }), // 예약날짜 YYYY.MM.DD
  setReserveTime: (value: string) => set({ reserveTime: value }), // 예약 시간 hh:mm
  setReserveTimeType: (value: string) => set({ reserveTimeType: value }),
  setMeetingDetailType: (value: string) => set({ meetingDetailType: value }),
  setMeetingType: (value: string) => set({ meetingType: value })
}));

export const useMeetingCheckbox = create<UseMeetingCheckbox>((set) => ({
  disabeld: true,
  checked: false,
  onChange: (value: boolean) => set({ checked: value }),
  setDisabled: (value: boolean) => set({ disabeld: value })
}));

export const useIsBannerAniRunning = create<IsRunningAniType>((set) => ({
  isGnbHeaderWithRunning: false,
  setIsGnbHeaderWithRunning: (value: boolean) => set({ isGnbHeaderWithRunning: value }),
  isRunningAni: false,
  setIsRunningAni: (value: boolean) => set({ isRunningAni: value })
}));

export const useMainWidthChange = create<UseMainWidthChange>((set) => ({
  mainWidthChange: 'notFull',
  setMainWidthChange: (type: 'full' | 'notFull') => set({ mainWidthChange: type })
}));

export const useGnbSticky = create<UseGnbSticky>((set) => ({
  isSticky: false,
  setIsSticky: (value: boolean) => set({ isSticky: value })
}));
export const useStickyValueFix = create<UseGnbStickyFix>((set) => ({
  isStickyFix: false,
  setIsStickyFix: (value: boolean) => set({ isStickyFix: value })
}));

export const useVisibleHeader = create<UseVisibleHeader>((set) => ({
  isVisibleHeader: false,
  setIsVisibleHeader: (value: boolean) => set({ isVisibleHeader: value })
}));

export const useAniDisabled = create<UseAniDisabled>((set) => ({
  aniDisabled: false,
  setAniDisabled: (value: boolean) => set({ aniDisabled: value })
}));

export const useIsScrollAni = create<UseIsScrollAni>((set) => ({
  isScrollAni: false,
  setIsScrollAni: (value: boolean) => set({ isScrollAni: value })
}));

export const useOutClick = create<UseOutClick>((set) => ({
  isActive: false,
  setIsActive: (value: boolean) => set({ isActive: value })
}));

export const useMainBannerIndex = create<UseMainBannerIndex>((set) => ({
  activeBannerIndex: -1,
  setActiveBannerIndex: (index: number) => set({ activeBannerIndex: index })
}));

export const useAnimationInProgress = create<UseAnimationInProgress>((set) => ({
  isActive: false,
  setIsActive: (value: boolean) => set({ isActive: value })
}));
export const useBannerSliderVideo = create<UseBannerSliderVideo>((set) => ({
  currentSliderIndex: 0,
  currentVideoEl: null,
  setCurrentSliderIndex: (index: number) => set({ currentSliderIndex: index }),
  setCurrentVideoEl: (video: HTMLVideoElement) => set({ currentVideoEl: video })
}));

export const useAboutMobileAni = create<UseAboutMobileAni>((set) => ({
  isComplete: false,
  setIsComplete: (value: boolean) => set({ isComplete: value })
}));

export const usePageTitleVisible = create<UsePageTitleVisibleType>((set) => ({
  isPageTitleVisible: true,
  setIsPageTitleVisible: (value: boolean) => set({ isPageTitleVisible: value })
}));

export const useMeetingEmailValidation = create<UseMeetingEmailValidation>((set) => ({
  isVisitroValidation: false,
  isAgencyValidation: false,
  setIsVisitroValidation: (value: boolean) => set({ isVisitroValidation: value }),
  setIsAgencyValidation: (value: boolean) => set({ isAgencyValidation: value })
}));

export const useRouterType = create<UseRouterType>((set) => ({
  routerType: '',
  setRouterType: (value: RouterType) => set({ routerType: value })
}));

export const useCurretEventPop = create<UseCurretEventPop>((set) => ({
  currentEventPop: false,
  setIsCurrentEventPop: (pop: boolean) => set({ currentEventPop: pop })
}));
export const useServiceQueryTab = create<UseServiceQueryTab>((set) => ({
  currentTab: 'game',
  setCurrentTab: (tab: string) => set({ currentTab: tab })
}));
