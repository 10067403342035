import { QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import HttpApiService from '@/lib/HttpApiService';
import type { QueryClientConfig } from '@tanstack/react-query';

export default function useInitQueryClient() {
  const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
      mutations: {
        onError: HttpApiService.handleError
      },
      queries: {
        onError: HttpApiService.handleError,
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: 1200,
        // keepPreviousData: true,
        networkMode: 'always'
      }
    }
  };
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));
  return queryClient;
}
